import { FC, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Header from './Header'
import Sidebar from 'components/Sidebar/Sidebar'
import clsx from 'clsx'
import { sidebar } from 'themes'
import { STORAGE_KEY } from 'constants/storageKey'

const BaseLayout: FC = ({ children }) => {
    const classes = useStyles()
    const [isCollapsed, setIsCollapsed] = useState<boolean>(JSON.parse(localStorage.getItem(STORAGE_KEY.SIDEBAR_COLLAPSED) || 'false'))

    const handleToggle = useCallback(() => {
        setIsCollapsed(!isCollapsed)
        localStorage.setItem(STORAGE_KEY.SIDEBAR_COLLAPSED, JSON.stringify(!isCollapsed))
    }, [isCollapsed])

    return (
        <div className={classes.root}>
            <Header />
            <Sidebar isCollapsed={isCollapsed} onToggle={handleToggle} />
            <div
                className={clsx(classes.main, {
                    [classes.mainCollapsed]: isCollapsed,
                })}
            >
                {children}
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        fontFamily: 'Hiragino Sans, sans-serif',
        background: '#EFEFEF',
        minHeight: '100vh',
    },
    main: {
        marginTop: 40,
        marginLeft: sidebar.width,
        minHeight: 'calc(100vh - 65px)',
        width: `calc(100% - ${sidebar.width})`,
        transition: 'margin-left 0.15s ease, width 0.15s ease',
    },
    mainCollapsed: {
        marginLeft: sidebar.widthCollapsed,
        width: `calc(100% - ${sidebar.widthCollapsed})`,
    },
}))

export default BaseLayout
