interface IProps {
    className?: string
}

export const ArrowForwardRightIcon = ({ ...rest }: IProps) => {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.9622 10.0446H2C1.44772 10.0446 1 10.498 1 11.0503C1 11.6025 1.44772 12.0559 2 12.0559H19.8704L14.5738 17.8665C14.2158 18.2592 14.2158 18.896 14.5738 19.2887C14.9318 19.6814 15.5122 19.6814 15.8701 19.2887L22.352 12.1779C22.4273 12.0953 22.4867 12.0019 22.5303 11.9022C22.8124 11.7238 23 11.4077 23 11.0503C23 10.7133 22.8334 10.4133 22.5781 10.2304C22.5352 10.081 22.4598 9.94031 22.3518 9.82184L15.87 2.71104C15.512 2.31832 14.9316 2.31832 14.5736 2.71104C14.2157 3.10376 14.2157 3.74048 14.5736 4.1332L19.9622 10.0446Z"
                fill="currentColor"
            />
        </svg>
    )
}
