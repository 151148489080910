export const SiteIcon = () => {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.66002 5.1242H19.34L20.49 13.6372H3.51002L4.66002 5.1242ZM3.63402 3.9502L2.16602 14.8112H21.833L20.366 3.9502H3.63402Z"
                fill="currentColor"
            />
            <path d="M15.4363 4.08947L14.2734 4.25098L15.7411 14.8185L16.904 14.657L15.4363 4.08947Z" fill="currentColor" />
            <path d="M9.18504 4.07753L7.7168 14.645L8.87963 14.8066L10.3479 4.23909L9.18504 4.07753Z" fill="currentColor" />
            <path d="M20.3477 6.98535H3.34766V7.98535H20.3477V6.98535Z" fill="currentColor" />
            <path d="M20.3477 9.98535H3.34766V10.9854H20.3477V9.98535Z" fill="currentColor" />
            <path d="M9.5068 14.1223L8.03906 17.498L9.1157 17.9662L10.5834 14.5904L9.5068 14.1223Z" fill="currentColor" />
            <path d="M14.4008 14.2059L13.3242 14.6743L14.7929 18.0498L15.8695 17.5814L14.4008 14.2059Z" fill="currentColor" />
            <path d="M18.3477 15.9854H5.34766V17.9854H18.3477V15.9854Z" fill="currentColor" />
        </svg>
    )
}
