export const LABEL = {
    SITE_ADD: 'サイトを追加する',
    SITE_EDIT: 'サイトを編集する',
    COMPANY_ADD: '企業を追加する',
    COMPANY_EDIT: '企業情報',
    VENDOR_ADD: 'PPA事業者を追加する',
    VENDOR_EDIT: 'PPA事業者情報',
    MAINTENANCE_ADD: 'メンテナンス事業者情報',
    USER_ADD: '利用者を追加する',
    USER_EDIT: '利用者情報を編集する',
    RECEIVING_POINT_ID: '受電地点特定番号',
}
