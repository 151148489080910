import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { startsWith } from 'lodash'
import { Box, List, ListItem, ListItemText } from '@material-ui/core'

import { SiteIcon, CompanyIcon, OperatorIcon, MaintenanceIcon, MenuIcon, UserIcon, ArrowForwardRightIcon, ArrowForwardLeftIcon } from 'components/Icon'
import { PATH_NAME } from 'constants/index'
import { Subjects } from 'casl/ability'
import { useAppAbility } from 'casl/Can'
import MenuItem from './MenuItem'
import { sidebar } from 'themes'
import { useStyles } from './style'

interface IProps {
    isCollapsed: boolean
    onToggle: (collapsed: boolean) => void
}

export type MenuItems = {
    name: string
    icon: React.ReactElement
    link: string
    subject: Subjects
}

const menuItems: MenuItems[] = [
    {
        name: 'サイト',
        icon: <SiteIcon />,
        link: PATH_NAME.SITE.INDEX,
        subject: 'Site',
    },
    {
        name: '利用企業',
        icon: <CompanyIcon />,
        link: PATH_NAME.COMPANY.INDEX,
        subject: 'Company',
    },
    {
        name: '事業者',
        icon: <OperatorIcon />,
        link: PATH_NAME.PPA_OPERATOR.INDEX,
        subject: 'Vendor',
    },
    {
        name: 'メンテナンス事業者',
        icon: <MaintenanceIcon />,
        link: PATH_NAME.MAINTENANCE_COMPANY.INDEX,
        subject: 'MaintenanceCompany',
    },
    {
        name: '利用者',
        icon: <UserIcon />,
        link: PATH_NAME.USER.INDEX,
        subject: 'Account',
    },
]

export default function Sidebar(props: IProps) {
    const ability = useAppAbility()
    const location = useLocation()
    const classes = useStyles()

    const { isCollapsed, onToggle } = props
    const [isHovered, setIsHovered] = useState(false)

    const handleSplitPathName = (name: string) => {
        return '/' + name.split('/')[1]
    }

    return (
        <Box className={classes.root} width={isCollapsed ? sidebar.widthCollapsed : sidebar.width}>
            <List component="nav" disablePadding>
                <ListItem
                    button
                    disableRipple
                    disableTouchRipple
                    className={classes.expandedIcon}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => onToggle(!isCollapsed)}
                >
                    <ToggleIcon isHovered={isHovered} isCollapsed={isCollapsed} className={classes.iconItem} />
                </ListItem>
                {menuItems
                    .filter((menuItem: MenuItems) => ability.can('view', menuItem.subject))
                    .map((item: MenuItems, index: number) => (
                        <MenuItem key={index} isCollapsed={isCollapsed} title={item.name}>
                            <ListItem
                                button
                                className={classes.listItem}
                                component={Link}
                                to={item.link}
                                selected={startsWith(item.link, handleSplitPathName(location.pathname))}
                            >
                                <div className={clsx(classes.iconContainer, { [classes.iconContainerCollapsed]: isCollapsed })}>
                                    <span className={classes.iconItem}>{item.icon}</span>
                                </div>
                                <ListItemText
                                    primary={item.name}
                                    className={clsx(classes.listItemText, {
                                        [classes.nonOpacity]: isCollapsed,
                                    })}
                                />
                            </ListItem>
                        </MenuItem>
                    ))}
            </List>
        </Box>
    )
}

interface ToggleIconProps {
    isHovered: boolean
    isCollapsed: boolean
    className: string
}

const ToggleIcon: React.FC<ToggleIconProps> = ({ isHovered, isCollapsed, className }) => {
    if (isHovered && isCollapsed) {
        return <ArrowForwardRightIcon className={className} />
    }

    if (isHovered && !isCollapsed) {
        return <ArrowForwardLeftIcon className={className} />
    }

    return <MenuIcon className={className} />
}
