export const CompanyIcon = () => {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.43692 4.8635H15.3905V17.1357H8.43692V4.8635ZM7.79688 17.7497H16.0329V4.24951H7.79688V17.7497Z" fill="currentColor" />
            <path d="M10.8337 7.47077H10.1146V6.78098H10.8345L10.8337 7.47077ZM11.4738 6.16699H9.47461V8.08476H11.4738V6.16699Z" fill="currentColor" />
            <path d="M10.8337 10.4634H10.1146V9.77365H10.8345L10.8337 10.4634ZM11.4738 9.15967H9.47461V11.0774H11.4738V9.15967Z" fill="currentColor" />
            <path d="M5.96066 12.3799H5.2416V11.6902H5.96145L5.96066 12.3799ZM6.60071 11.0762H4.60156V12.9939H6.60071V11.0762Z" fill="currentColor" />
            <path d="M5.2416 14.2219H5.96145V17.1364H5.2416V14.2219ZM4.60156 17.7497H6.60071V13.6079H4.60156V17.7497Z" fill="currentColor" />
            <path d="M18.6032 12.3799H17.8842V11.6902H18.604L18.6032 12.3799ZM19.2433 11.0762H17.2441V12.9939H19.2433V11.0762Z" fill="currentColor" />
            <path d="M17.8842 14.2219H18.604V17.1364H17.8842V14.2219ZM17.2441 17.7497H19.2433V13.6079H17.2441V17.7497Z" fill="currentColor" />
            <path d="M10.8337 13.4537H10.1146V12.7631H10.8345L10.8337 13.4537ZM11.4738 12.1499H9.47461V14.0677H11.4738V12.1499Z" fill="currentColor" />
            <path d="M13.8767 7.47077H13.1568V6.78098H13.8767V7.47077ZM14.5167 6.16699H12.5176V8.08476H14.5167V6.16699Z" fill="currentColor" />
            <path d="M13.8767 10.4634H13.1568V9.77365H13.8767V10.4634ZM14.5167 9.15967H12.5176V11.0774H14.5167V9.15967Z" fill="currentColor" />
            <path d="M3.64004 9.77365H7.71735V17.137H3.64004V9.77365ZM3 17.7502H8.35739V9.15967H3V17.7502Z" fill="currentColor" />
            <path d="M13.8767 13.4537H13.1568V12.7631H13.8767V13.4537ZM14.5167 12.1499H12.5176V14.0677H14.5167V12.1499Z" fill="currentColor" />
            <path d="M14.0354 16.983H9.87832V15.5261H14.0362L14.0354 16.983ZM14.6755 14.9121H9.23828V17.597H14.6755V14.9121Z" fill="currentColor" />
            <path d="M16.2826 9.77365H20.3599V17.137H16.2826V9.77365ZM15.6426 17.7502H21V9.15967H15.6426V17.7502Z" fill="currentColor" />
            <path d="M12.4835 15.4761H11.6934V16.9921H12.4835V15.4761Z" fill="currentColor" />
        </svg>
    )
}
