interface IProps {
    className?: string
}

export const MenuIcon = ({ ...rest }: IProps) => {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect x="1" y="4" width="22" height="2" fill="currentColor" />
            <rect x="1" y="10" width="22" height="2" fill="currentColor" />
            <rect x="1" y="16" width="22" height="2" fill="currentColor" />
        </svg>
    )
}
