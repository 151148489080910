interface IProps {
    className?: string
}

export const ArrowForwardLeftIcon = ({ ...rest }: IProps) => {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.0378 10.0447H22C22.5523 10.0447 23 10.498 23 11.0503C23 11.6026 22.5523 12.0559 22 12.0559H4.12957L9.42622 17.8665C9.7842 18.2592 9.7842 18.896 9.42622 19.2887C9.06824 19.6814 8.48784 19.6814 8.12985 19.2887L1.64804 12.1779C1.57275 12.0953 1.51329 12.0019 1.46967 11.9022C1.18758 11.7238 1 11.4078 1 11.0503C1 10.7134 1.16662 10.4133 1.42194 10.2305C1.46478 10.081 1.54019 9.94031 1.64818 9.82184L8.12999 2.71104C8.48797 2.31832 9.06837 2.31832 9.42635 2.71104C9.78434 3.10376 9.78434 3.74048 9.42635 4.1332L4.0378 10.0447Z"
                fill="currentColor"
            />
        </svg>
    )
}
