export const MaintenanceIcon = () => {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.32984 11.1391L14.3208 16.1301C14.3304 16.8694 14.6258 17.5767 15.1511 18.1035C15.6875 18.64 16.4012 18.9354 17.1596 18.9354C17.918 18.9354 18.6317 18.64 19.1682 18.1035C19.7046 17.5671 20 16.8534 20 16.095C20 15.3366 19.7046 14.6229 19.1682 14.0865C18.5806 13.4989 17.7679 13.2035 16.9457 13.2626L12.076 8.39294C12.5183 6.94162 12.1319 5.35618 11.0462 4.27048C9.92058 3.14647 8.22498 2.76807 6.72736 3.30613C6.55971 3.3668 6.43518 3.5073 6.39686 3.68133C6.35854 3.85537 6.41123 4.03578 6.53577 4.16191L8.11641 5.74256L7.72365 6.78834L6.67786 7.18111L5.09722 5.60046C4.97109 5.47433 4.79067 5.42164 4.61664 5.46156C4.44261 5.49988 4.3021 5.62441 4.24143 5.79206C3.70337 7.28968 4.08177 8.98528 5.20579 10.1093C6.29148 11.195 7.87692 11.5814 9.32824 11.1391H9.32984ZM5.04293 7.01346L6.17972 8.15025C6.32342 8.29395 6.53896 8.34025 6.72896 8.2684L8.30801 7.67606C8.44851 7.62337 8.55867 7.5132 8.61136 7.3727L9.20371 5.79365C9.27555 5.60366 9.22925 5.38811 9.08556 5.24442L7.94877 4.10763C8.81573 4.06133 9.68269 4.37746 10.3118 5.00652C11.1867 5.88147 11.4549 7.18909 10.9967 8.33865C10.9201 8.53184 10.9648 8.75058 11.1117 8.89747L16.3917 14.1775C16.5114 14.2972 16.6806 14.3515 16.8483 14.3212C17.4279 14.219 18.0218 14.4074 18.4353 14.8209C18.7754 15.161 18.9638 15.6144 18.9638 16.095C18.9638 16.5756 18.777 17.029 18.4353 17.3691C18.0952 17.7092 17.6418 17.8976 17.1612 17.8976C16.6806 17.8976 16.2272 17.7108 15.8871 17.3691C15.5167 16.9987 15.3251 16.4846 15.365 15.9593C15.3762 15.8092 15.3219 15.6607 15.215 15.5537L9.83596 10.1748C9.73697 10.0758 9.60445 10.0231 9.46874 10.0231C9.40328 10.0231 9.33942 10.0359 9.27715 10.0598C8.12759 10.5196 6.81996 10.2498 5.94502 9.37486C5.31595 8.74579 4.99823 7.87883 5.04613 7.01187L5.04293 7.01346Z"
                fill="currentColor"
            />
            <path
                d="M17.7092 16.6443C18.0125 16.341 18.0125 15.8492 17.7092 15.5475C17.4058 15.2457 16.914 15.2441 16.6123 15.5475C16.3089 15.8508 16.3089 16.3426 16.6123 16.6443C16.9156 16.9477 17.4074 16.9477 17.7092 16.6443Z"
                fill="currentColor"
            />
        </svg>
    )
}
