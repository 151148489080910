import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.green.dark,
        position: 'fixed',
        height: '100%',
        transition: 'width 0.15s ease-out',
        marginTop: 40,
    },
    listItem: {
        height: 48,
        textDecoration: 'none',
        color: '#FFF',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.green.light}`,
        cursor: 'pointer',
        padding: 0,
        '& .MuiTypography-body1': {
            fontFamily: 'Hiragino Sans, sans-serif',
            fontSize: '15px',
            fontWeight: '400',
            letterSpacing: 0,
            lineHeight: '21.72px',
            height: 22,
        },
        '&.Mui-selected': {
            '& svg path': {
                color: theme.palette.green.dark,
            },
            '& .MuiTypography-body1': {
                color: theme.palette.green.dark,
            },
        },
        '&.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover': {
            backgroundColor: theme.palette.background.default,
        },
        '&.MuiListItem-root:hover': {
            backgroundColor: theme.palette.green.light,
        },
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 48,
        padding: '0 4px 0 16px',
    },
    iconContainerCollapsed: {
        padding: '0 16px',
    },
    iconItem: {
        width: 24,
        height: 22,
        color: '#FFF',
    },
    listItemText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        padding: 0,
        margin: 0,
    },
    nonOpacity: {
        display: 'none',
    },
    expandedIcon: {
        height: 48,
        width: '100%',
        justifyContent: 'flex-end',
        padding: '0 16px',
        borderRadius: 'unset',
        borderBottom: `1px solid ${theme.palette.green.light}`,
        '&:hover': {
            backgroundColor: theme.palette.green.light,
        },
    },
}))
