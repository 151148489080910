export const OperatorIcon = () => {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0451 5.3849H10.3799V4.74317H11.0451V5.3849ZM11.6399 4.17188H9.78516V5.94837H11.6399V4.17188Z" fill="currentColor" />
            <path d="M11.0451 8.15491H10.3799V7.51318H11.0451V8.15491ZM11.6399 6.94971H9.78516V8.7262H11.6399V6.94971Z" fill="currentColor" />
            <path d="M13.8713 5.3849H13.2061V4.74317H13.8713V5.3849ZM14.4661 4.17188H12.6113V5.94837H14.4661V4.17188Z" fill="currentColor" />
            <path d="M13.8713 8.15491H13.2061V7.51318H13.8713V8.15491ZM14.4661 6.94971H12.6113V8.7262H14.4661V6.94971Z" fill="currentColor" />
            <path d="M12.5774 12.812H11.8418V14.2207H12.5774V12.812Z" fill="currentColor" />
            <path
                d="M16.2974 11.7633L15.6243 11.5599C15.5852 11.5442 15.5617 11.4973 15.5696 11.4503L15.7104 10.7538C15.7261 10.6599 15.6243 10.6051 15.5696 10.6755L14.8104 11.7242C14.7713 11.7712 14.7948 11.8494 14.8496 11.8651L15.5226 12.0686C15.5617 12.0842 15.5852 12.1312 15.5774 12.1781C15.5461 12.3268 15.4913 12.632 15.4365 12.8746C15.4209 12.9685 15.5226 13.0233 15.5774 12.9529L16.3365 11.9042C16.3756 11.8573 16.3522 11.779 16.2974 11.7633Z"
                fill="currentColor"
            />
            <path
                d="M14.2992 13.6806L14.2757 13.5789C14.2131 13.3363 14.1192 13.0937 14.0174 12.8511V14.1972H10.1592V12.8433H14.0174C13.9392 12.6633 13.8453 12.4754 13.7435 12.3033C13.7435 12.2955 13.7435 12.2876 13.7279 12.272H9.56445V14.338H14.6044V14.2598H14.44C14.3461 14.2598 14.2679 14.1815 14.2679 14.0876C14.2679 13.9937 14.3461 13.9154 14.44 13.9154H14.6044V13.6728H14.2992V13.6806Z"
                fill="currentColor"
            />
            <path d="M14.1133 12.2876C14.3324 12.6789 14.5046 13.0858 14.6063 13.4928L14.1133 12.2876Z" fill="currentColor" />
            <path
                d="M17.7051 11.2075C17.7051 11.411 17.6738 11.6145 17.6112 11.8101C18.7068 12.3501 19.372 13.0466 19.372 13.8058C19.372 15.277 16.9382 16.5057 13.6747 16.8266C13.3304 16.8579 12.9782 16.8814 12.6261 16.897L12.9391 15.9892L9.73828 17.797L12.9391 19.6048L12.673 18.83C13.0174 18.8144 13.3539 18.7987 13.6826 18.7674C17.846 18.3918 20.9998 16.6388 20.9998 14.5257C20.9998 13.1797 19.7164 11.9901 17.7051 11.1997V11.2075Z"
                fill="currentColor"
            />
            <path
                d="M3 14.5414C3 16.584 5.95039 18.29 9.92598 18.7439L8.2747 17.8126L10.0512 16.8109C6.92081 16.4509 4.61998 15.2457 4.61998 13.8214C4.61998 12.6866 6.07561 11.7006 8.23557 11.1606V10.6362C5.15214 11.3171 3 12.8118 3 14.5492L3 14.5414Z"
                fill="currentColor"
            />
            <path
                d="M14.3543 14.776H9.5648V14.3456H8.82134V11.0117C8.61786 11.0508 8.42221 11.0978 8.22656 11.1448V14.9169H14.6047V14.8151H14.4404C14.4404 14.8151 14.3778 14.7995 14.3543 14.776Z"
                fill="currentColor"
            />
            <path
                d="M9.56445 14.7761H14.3539C14.307 14.7448 14.2679 14.6979 14.2679 14.6431C14.2679 14.5492 14.3461 14.4709 14.44 14.4709H14.6044V14.3535H9.56445V14.7839V14.7761Z"
                fill="currentColor"
            />
            <path
                d="M8.82328 2.9668H15.2719V9.14149C15.3893 9.12584 15.5067 9.11019 15.6319 9.11801C15.7101 9.11801 15.7884 9.13367 15.8666 9.14932V2.39551H8.23633V10.6206C8.43198 10.5736 8.62763 10.5345 8.8311 10.5032V2.9668H8.82328Z"
                fill="currentColor"
            />
            <path
                d="M8.82134 10.5034C8.61786 10.5425 8.42221 10.5817 8.22656 10.6208V11.1451C8.42221 11.0982 8.61786 11.0512 8.82134 11.0121V10.5034Z"
                fill="currentColor"
            />
            <path
                d="M11.0451 10.9335H10.3799V10.7848C10.1765 10.8005 9.98081 10.8239 9.78516 10.8474V11.497H11.6399V10.7065C11.4364 10.7065 11.2408 10.7144 11.0451 10.73V10.9257V10.9335Z"
                fill="currentColor"
            />
            <path
                d="M10.3799 10.292H10.4269C10.826 10.2607 11.233 10.2372 11.6399 10.2294V9.7207H9.78516V10.3546C9.98081 10.3311 10.1765 10.3077 10.3799 10.292Z"
                fill="currentColor"
            />
            <path
                d="M11.0518 10.2916V10.7377C11.2475 10.7299 11.4431 10.722 11.6466 10.7142V10.229C11.2318 10.229 10.8249 10.2603 10.4336 10.2916H11.0597H11.0518Z"
                fill="currentColor"
            />
            <path
                d="M10.3799 10.785V10.292C10.1765 10.3076 9.98081 10.3311 9.78516 10.3546V10.8476C9.98081 10.8242 10.1765 10.8007 10.3799 10.785Z"
                fill="currentColor"
            />
            <path
                d="M12.6094 10.2372C12.9381 10.245 13.2589 10.2685 13.572 10.292H13.6424C13.7442 10.0807 13.8694 9.89287 14.0337 9.7207H12.6016V10.2372H12.6094Z"
                fill="currentColor"
            />
            <path
                d="M13.2061 10.754C13.0105 10.7383 12.8148 10.7305 12.6113 10.7227V11.5053H13.48C13.4644 11.4035 13.4409 11.3018 13.4409 11.2C13.4409 11.114 13.4565 11.0279 13.4644 10.9418H13.2061V10.7618V10.754Z"
                fill="currentColor"
            />
            <path
                d="M13.2061 10.7533V10.2916H13.5739C13.2609 10.2681 12.94 10.2446 12.6113 10.2368V10.722C12.8148 10.722 13.0105 10.7377 13.2061 10.7533Z"
                fill="currentColor"
            />
            <path
                d="M16.7026 14.2677C16.7965 14.2677 16.8748 14.1894 16.8748 14.0955C16.8748 14.0016 16.7965 13.9233 16.7026 13.9233H15.873V14.2677H16.7026Z"
                fill="currentColor"
            />
            <path d="M15.2785 13.9233H14.6133V14.2677H15.2785V13.9233Z" fill="currentColor" />
            <path
                d="M14.2695 14.0955C14.2695 14.1894 14.3478 14.2677 14.4417 14.2677H14.606V13.9233H14.4417C14.3478 13.9233 14.2695 14.0016 14.2695 14.0955Z"
                fill="currentColor"
            />
            <path d="M15.8741 13.9233H15.2793V14.2677H15.8741V13.9233Z" fill="currentColor" />
            <path
                d="M16.7026 14.815C16.7965 14.815 16.8748 14.7368 16.8748 14.6429C16.8748 14.549 16.7965 14.4707 16.7026 14.4707H15.873V14.815H16.7026Z"
                fill="currentColor"
            />
            <path
                d="M14.6137 14.7759H14.3555C14.3555 14.7759 14.4103 14.815 14.4416 14.815H15.8659V14.4707H14.6059V14.7837L14.6137 14.7759Z"
                fill="currentColor"
            />
            <path
                d="M14.2695 14.6356C14.2695 14.6982 14.3087 14.7373 14.3556 14.7686H14.6139V14.4556H14.4495C14.3556 14.4556 14.2774 14.5338 14.2774 14.6277L14.2695 14.6356Z"
                fill="currentColor"
            />
            <path
                d="M16.8756 15.1829C16.8756 15.089 16.7973 15.0107 16.7034 15.0107H14.4417C14.3478 15.0107 14.2695 15.089 14.2695 15.1829C14.2695 15.2768 14.3478 15.3551 14.4417 15.3551H16.7034C16.7973 15.3551 16.8756 15.2768 16.8756 15.1829Z"
                fill="currentColor"
            />
            <path
                d="M15.577 16.0599C15.8509 16.0599 16.0779 15.8564 16.0779 15.606H15.084C15.084 15.8564 15.3109 16.0599 15.5848 16.0599H15.577Z"
                fill="currentColor"
            />
            <path
                d="M14.4648 10.8871C14.504 10.8871 14.5431 10.9027 14.5822 10.9106C14.637 10.7775 14.7231 10.6523 14.8327 10.5427C14.864 10.5114 14.8953 10.488 14.9266 10.4645C14.7857 10.441 14.6527 10.4175 14.504 10.394C14.4883 10.4097 14.4805 10.4253 14.4648 10.441V10.8871Z"
                fill="currentColor"
            />
            <path
                d="M14.5039 10.3939C14.6448 10.4174 14.7856 10.4409 14.9265 10.4643C15.0282 10.3783 15.1456 10.3078 15.2787 10.2609V9.90088C15.0204 9.97131 14.7856 10.1044 14.59 10.2844C14.5587 10.3157 14.5352 10.3548 14.5039 10.3861V10.3939Z"
                fill="currentColor"
            />
            <path
                d="M14.4648 11.3719H14.4883C14.4883 11.2076 14.5196 11.0511 14.5822 10.9102C14.5431 10.9102 14.504 10.8945 14.4648 10.8867V11.3719Z"
                fill="currentColor"
            />
            <path
                d="M15.7489 10.1822V9.83789C15.5845 9.83789 15.428 9.86919 15.2793 9.90832V10.2683C15.428 10.2135 15.5845 10.1822 15.7489 10.1822Z"
                fill="currentColor"
            />
            <path
                d="M14.2384 10.8555C14.1837 11.0198 14.1445 11.192 14.1445 11.372H14.4654V10.8868C14.3871 10.8789 14.3167 10.8711 14.2384 10.8555Z"
                fill="currentColor"
            />
            <path
                d="M14.4652 10.4404C14.3635 10.5656 14.2931 10.7065 14.2383 10.8552C14.3165 10.863 14.3948 10.8709 14.4652 10.8865V10.4404Z"
                fill="currentColor"
            />
            <path
                d="M17.7121 11.192C17.7121 10.1198 16.9296 9.28247 15.873 9.1416V9.48594C16.7026 9.61116 17.3052 10.2529 17.36 11.0746C17.4774 11.1137 17.5948 11.1607 17.7121 11.2077V11.192Z"
                fill="currentColor"
            />
            <path
                d="M17.1183 12.1229C16.8522 12.5846 16.6487 13.062 16.5235 13.5394L16.4922 13.6568C16.4922 13.6568 16.4922 13.6724 16.4922 13.6802H16.8444L16.86 13.6255C16.9774 13.1794 17.1652 12.7333 17.4157 12.295C17.5017 12.1385 17.5722 11.9742 17.6191 11.802C17.5174 11.7472 17.4235 11.7003 17.3061 11.6533C17.267 11.8177 17.2044 11.9742 17.1183 12.1151V12.1229Z"
                fill="currentColor"
            />
            <path
                d="M13.4785 10.7777C13.5959 10.7855 13.7133 10.7933 13.8229 10.809C13.8385 10.7542 13.8542 10.6994 13.8698 10.6525V10.3159C13.7916 10.3159 13.7211 10.3003 13.6429 10.3003C13.5724 10.449 13.5098 10.6133 13.4785 10.7777Z"
                fill="currentColor"
            />
            <path
                d="M14.043 9.72072H14.4656V9.82246C14.7003 9.64246 14.9743 9.52507 15.2795 9.48594V9.1416C14.8099 9.20421 14.3795 9.39986 14.043 9.72855V9.72072Z"
                fill="currentColor"
            />
            <path d="M14.6524 13.681C14.6524 13.681 14.6524 13.6653 14.6524 13.6575L14.6133 13.5088V13.6888H14.6602L14.6524 13.681Z" fill="currentColor" />
            <path
                d="M13.729 12.288H14.1124C14.0811 12.241 14.0655 12.1862 14.0342 12.1393C13.9168 11.9436 13.8542 11.7323 13.815 11.5054H13.4707C13.5098 11.7793 13.5881 12.0454 13.7211 12.288H13.729Z"
                fill="currentColor"
            />
            <path
                d="M13.7442 12.3111C13.8537 12.4911 13.9398 12.6711 14.0181 12.8511C14.1276 13.0937 14.2137 13.3363 14.2763 13.5789L14.2998 13.6806H14.605V13.5006C14.5033 13.078 14.3233 12.6789 14.112 12.2876H13.7285C13.7285 12.2876 13.7285 12.3032 13.7442 12.3189V12.3111Z"
                fill="currentColor"
            />
            <path
                d="M13.4785 10.7769C13.4707 10.8238 13.4707 10.8786 13.4629 10.9334H13.8072C13.8072 10.8942 13.8151 10.8473 13.8307 10.8082C13.7133 10.7925 13.5959 10.7847 13.4864 10.7769H13.4785Z"
                fill="currentColor"
            />
            <path d="M13.8695 10.3155V10.292H13.6426C13.7208 10.292 13.7991 10.2998 13.8695 10.3076V10.3155Z" fill="currentColor" />
            <path
                d="M17.3614 11.0747C17.3614 11.0747 17.3692 11.153 17.3692 11.1843C17.3692 11.3408 17.3458 11.4973 17.3066 11.6538C17.4162 11.7008 17.5101 11.7556 17.6197 11.8025C17.6745 11.6069 17.7058 11.4034 17.7136 11.1999C17.5962 11.153 17.4788 11.1139 17.3614 11.0669V11.0747Z"
                fill="currentColor"
            />
            <path
                d="M15.6295 9.11816C15.5043 9.11816 15.3869 9.12599 15.2695 9.14164V9.48598C15.3869 9.47033 15.4965 9.46251 15.6217 9.46251C15.7078 9.46251 15.786 9.47816 15.8721 9.49381V9.14947C15.7939 9.14164 15.7156 9.12599 15.6374 9.11816H15.6295Z"
                fill="currentColor"
            />
            <path
                d="M13.6426 10.292H13.8695V10.3155C13.9165 10.3155 13.9634 10.3311 14.0104 10.3311C14.0887 10.1981 14.1669 10.0807 14.2765 9.97113C14.3313 9.91635 14.4017 9.8694 14.4643 9.82244V9.7207H14.0417C13.8774 9.88505 13.7443 10.0807 13.6504 10.292H13.6426Z"
                fill="currentColor"
            />
            <path
                d="M13.7995 10.9341H13.4551C13.4473 11.0202 13.4316 11.1063 13.4316 11.1923C13.4316 11.2941 13.4551 11.3958 13.4708 11.4976H13.8151C13.7995 11.3958 13.776 11.2941 13.776 11.1923C13.776 11.1063 13.7916 11.0202 13.7995 10.9341Z"
                fill="currentColor"
            />
            <path
                d="M14.01 10.3311C13.9631 10.3311 13.9161 10.3154 13.8691 10.3154V10.6519C13.9083 10.5424 13.9552 10.4328 14.01 10.3389V10.3311Z"
                fill="currentColor"
            />
        </svg>
    )
}
