export const UserIcon = () => {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_5095_41525" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="5" y="2" width="14" height="18">
                <path d="M18.1875 2H5.8125V20H18.1875V2Z" fill="white" />
            </mask>
            <g mask="url(#mask0_5095_41525)">
                <mask id="path-2-inside-1_5095_41525" fill="white">
                    <path d="M14.3504 11.7449C13.6117 12.1596 12.7788 12.3774 11.9316 12.3774C11.0845 12.3774 10.2516 12.1596 9.51289 11.7449C7.49802 12.5549 5.60352 14.374 5.60352 16.732V19.5996H18.2598V16.732C18.2598 14.3695 16.3641 12.5549 14.3504 11.7494" />
                </mask>
                <path
                    d="M9.51289 11.7449L10.0024 10.8729L9.58454 10.6383L9.13989 10.817L9.51289 11.7449ZM5.60352 19.5996H4.60352V20.5996H5.60352V19.5996ZM18.2598 19.5996V20.5996H19.2598V19.5996H18.2598ZM13.8609 10.8729C13.2717 11.2037 12.6073 11.3774 11.9316 11.3774V13.3774C12.9502 13.3774 13.9517 13.1155 14.8399 12.6169L13.8609 10.8729ZM11.9316 11.3774C11.2559 11.3774 10.5916 11.2037 10.0024 10.8729L9.02336 12.6169C9.91157 13.1155 10.9131 13.3774 11.9316 13.3774V11.3774ZM9.13989 10.817C6.92658 11.7068 4.60352 13.8011 4.60352 16.732H6.60352C6.60352 14.9469 8.06945 13.4029 9.88589 12.6727L9.13989 10.817ZM4.60352 16.732V19.5996H6.60352V16.732H4.60352ZM5.60352 20.5996H18.2598V18.5996H5.60352V20.5996ZM19.2598 19.5996V16.732H17.2598V19.5996H19.2598ZM19.2598 16.732C19.2598 13.796 16.9348 11.7061 14.7218 10.8209L13.979 12.6778C15.7935 13.4036 17.2598 14.943 17.2598 16.732H19.2598Z"
                    fill="currentColor"
                    mask="url(#path-2-inside-1_5095_41525)"
                />
                <path
                    d="M15.8812 6.44938C15.8812 7.23049 15.6495 7.99406 15.2156 8.64353C14.7816 9.293 14.1648 9.7992 13.4432 10.0981C12.7215 10.397 11.9274 10.4753 11.1613 10.3229C10.3952 10.1705 9.6915 9.79433 9.13917 9.242C8.58684 8.68967 8.2107 7.98596 8.05831 7.21986C7.90592 6.45376 7.98413 5.65967 8.28305 4.93801C8.58197 4.21636 9.08817 3.59955 9.73764 3.16559C10.3871 2.73163 11.1507 2.5 11.9318 2.5C12.9792 2.5 13.9838 2.91609 14.7244 3.65675C15.4651 4.3974 15.8812 5.40194 15.8812 6.44938Z"
                    stroke="currentColor"
                />
            </g>
        </svg>
    )
}
