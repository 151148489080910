export const VALIDATION = {
    REQUIRED: ':attrを入力してください',
    DIGITS: ':attrは数字:digits文字です',
    NUMERIC: ':attrには数値でなければなりません',
    EXISTS: '既に:attrが登録されています',
    UNIQUE: ':attrが重複しています',
    POSTAL_CODE: '郵便番号は以下の形式で入力してください: ###-####',

    // yup
    STRING_RANGE: '${path}は${min}〜${max}文字にしてください',
    STRING_MIN: '${path}は${min}文字以上にしてください',
    STRING_MAX: '${path}は${max}文字以下にしてください',
    NUMBER_RANGE: '${path}は${min}〜${max}の範囲にしてください',
    NUMBER_MIN: '${path}は${min}以上にしてください',
    NUMBER_MAX: '${path}は${max}以下にしてください',
    LENGTH: '${path}は数字${length}文字です',
    EMAIL: '正しいメールアドレスを入力してください',
}
